import {
  StatDataUserPerQuestion,
  StatDataUserPerQuestionAnswer,
  TestResponsePrivate,
} from '@tests/types';
import { Row } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';

import { StatsAnswerBlock } from '@/components';

import { numberShorter } from '../../utils';
import styles from './styles.module.scss';

interface Props {
  test: TestResponsePrivate;
  userPerQuestion: StatDataUserPerQuestion;
  userPerQuestionAnswers: StatDataUserPerQuestionAnswer;
}

const TextWidthMeasurer: FC<{ onWidthMeasured: (width: number) => void; text: string }> = ({
  onWidthMeasured,
  text,
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (spanRef.current) {
      onWidthMeasured(Math.max(spanRef.current.offsetWidth, 30));
    }
  }, [text, onWidthMeasured]);

  return (
    <span
      ref={spanRef}
      style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap' }}
    >
      {text}
    </span>
  );
};

const TickY = (props: any) => {
  const { payload, x, y } = props;

  const value = payload.value ? numberShorter(payload.value) : 0;

  return (
    <g transform={`translate(${x - 5},${y})`}>
      <text
        x={0}
        y={0}
        dy={2}
        textAnchor="end"
        fill="#AAADB6"
        fontFamily="Rubik"
        fontSize="12"
        fontWeight="400"
      >
        {value}
      </text>
    </g>
  );
};

const TickX = (props: any) => {
  const { payload, x, y } = props;

  const { value } = payload;

  return (
    <g transform={`translate(${x},${y + 10})`}>
      <text
        x={0}
        y={0}
        dy={2}
        textAnchor="middle"
        fill="#AAADB6"
        fontFamily="Rubik"
        fontSize="12"
        fontWeight="400"
      >
        {value}
      </text>
    </g>
  );
};

const CustomTooltip: FC<any> = (props) => {
  const { active, payload, tooltipVisible, viewBox, width } = props;
  if (active && payload && payload.length && tooltipVisible) {
    return (
      <div className={styles.tooltipContainer} style={{ width: `${width}px` }}>
        <div className={styles.tooltipWrapper}>
          <span className={styles.tooltipText}>{payload[0].value}</span>
        </div>
        <svg
          className={styles.tooltipArrow}
          width="14"
          height="5"
          style={{
            bottom: '-5px',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-50%)',
          }}
        >
          <polygon points="0,0 14,0 5,5" fill="currentColor" />
        </svg>
      </div>
    );
  }

  return null;
};

export const StatsQuestionsBlock: FC<Props> = ({
  test,
  userPerQuestion,
  userPerQuestionAnswers,
}) => {
  const { questions } = test;
  const [activeIndex, setActiveIndex] = useState(0);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [barGraphData, setBarGraphData] = useState({ width: 0, x: 0, y: 0 });
  const [yAxisWidth, setYAxisWidth] = useState(0);

  const sortedQuestions = [...questions].sort((a, b) => a.id - b.id);

  const data = questions.map((question, index) => ({
    name: index + 1,
    question: userPerQuestion.items[index],
  }));

  const handleBarClick: CategoricalChartFunc = (e) => {
    setActiveIndex(data.findIndex((item) => e.activePayload[0].payload.name === item.name));
  };

  const getMaxDomainValue = (value: number) => {
    if (value < 10) {
      return Math.ceil(value) + 1;
    }
    if (value < 1000) {
      return Math.ceil(value / 10) * 10;
    }
    if (value < 10000) {
      return Math.ceil(value / 1000) * 1000;
    }
    if (value < 1000000) {
      return Math.ceil(value / 1000) * 1000;
    }

    return Math.ceil(value / 1000000) * 1000000;
  };

  const maxYValue = Math.max(...data.map((item) => item.question));
  const domainMax = getMaxDomainValue(maxYValue);

  return (
    <>
      <TextWidthMeasurer text={numberShorter(domainMax)} onWidthMeasured={setYAxisWidth} />
      <div className={styles.wrapper}>
        <div
          className={styles.chart}
          style={{ minWidth: `${data.length * 20 + (data.length - 1) * 40 + yAxisWidth + 30}px` }}
        >
          <ResponsiveContainer width="100%">
            <BarChart
              width={500}
              height={200}
              data={data}
              margin={{
                bottom: 5,
                left: 10,
                right: 0,
                top: 5,
              }}
              onClick={handleBarClick}
            >
              <CartesianGrid vertical={false} stroke="#343B4C" />
              <XAxis dataKey="name" tick={<TickX />} axisLine={false} stroke="transparent" />
              <YAxis
                tickCount={3}
                width={yAxisWidth}
                stroke="#343B4C"
                axisLine={false}
                ticks={[0, Math.ceil(domainMax / 2), domainMax]}
                domain={[0, domainMax]}
                tick={<TickY />}
              />
              <Tooltip
                position={{ x: barGraphData.x, y: barGraphData.y - 50 }}
                content={
                  <CustomTooltip width={barGraphData.width} tooltipVisible={tooltipVisible} />
                }
                animationDuration={0}
                cursor={{ fill: 'transparent' }}
              />
              <Bar
                radius={[2, 2, 2, 2]}
                dataKey="question"
                fill="#6D727F"
                barSize={20}
                activeIndex={activeIndex}
                onMouseOver={(data) => {
                  setBarGraphData(data);
                  setTooltipVisible(true);
                }}
                onMouseOut={() => {
                  setTooltipVisible(false);
                }}
                cursor="pointer"
                maxBarSize={22}
                activeBar={<Rectangle fill="#3263E9" stroke="#3263E9" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className={styles.question}>
        <div className={styles.questionsTitle}>Вопрос № {activeIndex + 1}</div>
        <div className={styles.questionsContent}>{sortedQuestions[activeIndex]?.text}</div>
        <Row gutter={[15, 15]}>
          {sortedQuestions[activeIndex]?.answers.map((answer) => (
            <StatsAnswerBlock
              answer={answer}
              userPerQuestionAnswer={userPerQuestionAnswers?.items[activeIndex]}
            />
          ))}
        </Row>
      </div>
    </>
  );
};
