import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

export enum ProgressPosition {
  left = 'left',
  right = 'right',
}

export enum ProgressType {
  count = 'count',
  hidden = 'hidden',
  percent = 'percent',
}

interface Props {
  currentProgress: number;
  maxProgress: number;
  position?: ProgressPosition;
  type?: ProgressType;
}

export const Progress: React.FC<Props> = ({
  currentProgress,
  maxProgress,
  position = ProgressPosition.left,
  type = ProgressType.count,
}) => {
  const completionProgress = Math.round((currentProgress / maxProgress) * 100);

  return (
    <div className={classnames(styles[position], styles.container)}>
      {type === ProgressType.count && (
        <div className={styles.progressNumbers}>
          <div className={styles.currentProgress}>{currentProgress}</div>
          <div className={styles.maxProgress}>/</div>
          <div className={styles.maxProgress}>{maxProgress}</div>
        </div>
      )}
      {type === ProgressType.percent && (
        <div className={styles.progressPercents}>
          <div className={styles.currentProgress}>{`${completionProgress}%`}</div>
        </div>
      )}
      <div className={styles.progressBar}>
        <div className={styles.fill} style={{ width: `${completionProgress}%` }} />
      </div>
    </div>
  );
};
