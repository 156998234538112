import {
  Answer,
  StatDataUserPerQuestionAnswerItem,
} from '@tests/types';
import { Col, Row } from 'antd';
import { FC } from 'react';

import { Published, Users } from '@/assets';
import { Progress, ProgressType } from '@/atoms';

import styles from './styles.module.scss';

interface Props {
  answer: Answer<string>;
  userPerQuestionAnswer: StatDataUserPerQuestionAnswerItem;
}

export const StatsAnswerBlock: FC<Props> = ({ answer, userPerQuestionAnswer = {} }) => {
  const { isCorrect, text } = answer;
  const { usersCount, usersPercent } = userPerQuestionAnswer;

  return (
    <Col span={24}>
      <Row gutter={[5, 5]}>
        <Col span={24}>
          <div className={styles.infoWrapper}>
            <div className={styles.answerText}>
              {text}
              {isCorrect && <Published />}
            </div>
            <div className={styles.answerCounts}>
              <div className={styles.answerCount}>
                <Users className={styles.usersIcon} />
                {usersCount || 0}
              </div>
              <div className={styles.answerPercentage}>{usersPercent || 0}</div>
              <div>%</div>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Progress
            currentProgress={usersCount || 0}
            maxProgress={100}
            type={ProgressType.hidden}
          />
        </Col>
      </Row>
    </Col>
  );
};
