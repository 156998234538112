export enum StatType {
  UserPerQuestion = 'userPerQuestion',
  UserPerQuestionAnswer = 'userPerQuestionAnswer',
  Sources = 'sources',
  Regions = 'regions',
  Devices = 'devices',
  Links = 'links',
  UserStarts = 'userStarts',
  UserFinishes = 'userFinishes',
  UserFinishesDuration = 'userFinishesDuration',
  UserClickShowAnswers = 'userClickShowAnswers',
}
